import React, {lazy, useState, Suspense, useEffect} from 'react';
import {useTypedDispatch, useTypedSelector} from "../../../redux/hooks";
import {
    selectActiveChat,
    selectChatList,
    selectCurrentUserId, selectFiltersByPage,
    selectThreadsList, selectUnreadThreadMessages, setChannelCreation, setPageFilter
} from '../../../redux/slices';
import {
    usePostAllMessagesReadMutation,
} from "../../../redux/services/chatApi";
import Icon from "../../Icon";
import {TextInput} from "../../forms";
import {useDebounce} from "../../../helpers";
import {useChatDataSet} from "./hooks";
import {usePolyglot} from "../../../context/Polyglot";
import {Filters} from "../../menus/Filters";
import MenuHeader from "../../menus/MenuHeader";
import {AriaCheckbox} from "../../AriaComponents";
import {IChat, IChatMessage, IThread} from "../../../types";
import ChatListSkeleton from "../../Skeletons/ChatListSkeleton";
import ModalFormContainer from "../../forms/modalFormContainer/ModalFormContainer";
import LoadingSpinner from "../../loading/LoadingSpinner";
import {StyledButton} from "../../StyledComponents";
import NewChat from "../../forms/NewChat";
import StyledDropdown, {StyledDropdownOption} from "../../StyledComponents/StyledDropdown";

const ChatList = lazy(() => import('./ChatList'))

interface ChatListProps {
    chatsLoading: boolean;
}

export type ChatDataType =
    IChatMessage
    | IChat
    | IThread
    | { divider: 'message' | 'empty', message: string }
    | { divider: 'loader' }

export type ChatListPageType = 'chats' | 'threads'

export const ChatSidebar: React.FC<ChatListProps> = ({chatsLoading}) => {

    const groupCreationIsActive = useTypedSelector(state => state.chat.groupCreationActive);
    const filter = useTypedSelector(state => selectFiltersByPage(state, 'chatList'));
    const unreadThreadsCount = useTypedSelector(selectUnreadThreadMessages);
    const currentUser = useTypedSelector(selectCurrentUserId);
    const threadsList = useTypedSelector(selectThreadsList);
    const activeChat = useTypedSelector(selectActiveChat);
    const chatsList = useTypedSelector(selectChatList);

    const [page, setPage] = useState<ChatListPageType>('chats');
    const [searchTerm, setSearchTerm] = useState<string>('');

    const searchQueryDebounced = useDebounce(searchTerm, 500);

    const { dataSet, chats, threads } = useChatDataSet({
        searchQueryDebounced,
        searchRegexDebounced: searchQueryDebounced ? new RegExp(searchQueryDebounced, 'i') : undefined,
        page
    });

    const {t} = usePolyglot();

    const dispatch = useTypedDispatch();

    const [postAllMessagesRead] = usePostAllMessagesReadMutation();


    const filters = [
        [
            {title: 'misc', value: ''},
            {title: t('adjective.chats_unread'), value: 'Unread'},
            {title: t('adjective.chats_read'), value: 'Read'},
            {title: t('adjective.chats_pinned'), value: 'Pinned'},
            {title: t('adjective.chats_muted'), value: 'Muted'},
            {title: t('adjective.chats_sms'), value: 'SMS'},
        ],
    ]

    function handleFilterChange(selectedFilter) {
        let newFilters: string[]

        switch (true) {
            case selectedFilter === 'Read' && filter.includes('Unread'):
                newFilters = filter
                    .filter(f => f !== 'Unread')
                    .concat(selectedFilter)
                break;
            case selectedFilter === 'Unread' && filter.includes('Read'):
                newFilters = filter
                    .filter(f => f !== 'Read')
                    .concat(selectedFilter)
                break;
            case selectedFilter === 'Groups' && filter.includes('Individual'):
                newFilters = filter
                    .filter(f => f !== 'Individual')
                    .concat(selectedFilter)
                break;
            case selectedFilter === 'Individual' && filter.includes('Groups'):
                newFilters = filter
                    .filter(f => f !== 'Groups')
                    .concat(selectedFilter)
                break;
            case filter.includes(selectedFilter):
                newFilters = filter
                    .filter(f => f !== selectedFilter)
                break;
            default:
                newFilters = filter.concat(selectedFilter)
        }

        dispatch(
            setPageFilter({
                page: 'chatList',
                newFilters
            })
        )
    }

    const handleMarkAllRead = () => {
        if (chatsList?.length < 1) return;

        chatsList.concat(threadsList).forEach(chat => {
            if (chat.unread_count > 0) {
                postAllMessagesRead({
                    voip_user_uuid: currentUser,
                    type: chat.type,
                    chat_uuid: chat.uuid,
                })
            }
        });
    }

    useEffect(() => {
        if (searchTerm && activeChat) {
            setSearchTerm("")
        }
    }, [activeChat]);

    const dropDownOptions: StyledDropdownOption[] = [{
        label: t("actions.mark_all_as_read"),
        value: 'mark_all_as_read',
    }]

    const handleDropdownOption = (val: string) => {
        switch (val) {
            case 'mark_all_as_read':
                handleMarkAllRead();
                break;
        }
    }

    return (
        <div className='chat-sidebar'>
            <MenuHeader
                title={`${t("terms.chat", 1)} & ${t("abbreviations.sms")}`}
                titleOptions={
                    <div className='menu-header__icon-container'>
                        <StyledButton
                            buttonStyle='tertiary'
                            fontWeight='medium'
                            onClick={() => dispatch(setChannelCreation(true))}
                            title={t("phrases.new_chat")}
                        >
                            <Icon name='plus' width={24} height={24}/>
                            <p>{t("phrases.new_chat")}</p>
                        </StyledButton>
                        <StyledButton
                            buttonStyle='tertiary'
                            iconOnly
                            onClick={() => setPage(page === 'threads' ? 'chats' : 'threads')}
                            title={page === 'threads' ? t("actions.go_to_chats") : t("actions.go_to_threads")}
                            size='large'
                            className='menu-header__icon-extra-padding'
                        >
                            {page === 'threads' ? (
                                <Icon name='dualChatBubbleFill' ariaHidden width={20} height={20}/>
                            ) : (
                                <>
                                    <Icon name='dualChatBubbleUnfill' ariaHidden width={20} height={20}/>
                                    {unreadThreadsCount > 0 && (
                                        <i className='menu-header__icon-notification'/>
                                    )}
                                </>
                            )}
                        </StyledButton>
                        <StyledDropdown
                            options={dropDownOptions}
                            iconOnly
                            onSelect={handleDropdownOption}
                            iconSize='large'
                        />
                    </div>
                }
                input={
                    <TextInput
                        name='Chat Search'
                        placeholder={t("actions.search_chat", 2)}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        isSearch
                        isSearching={searchTerm !== searchQueryDebounced}
                    />
                }
                filter={
                    <Filters
                        filter={filter}
                        filters={filters}
                        onChange={(f) => handleFilterChange(f)}
                        reset={() => {
                            dispatch(
                                setPageFilter({
                                    page: 'chatList',
                                    newFilters: []
                                })
                            )
                        }}
                        label='Chats Filter'
                    >
                        {page === 'chats' ? (
                            <>
                                <p>{t("terms.type", 1)}</p>
                                <AriaCheckbox value='Individual'>
                                    {t("adjective.chats_individual")}
                                </AriaCheckbox>
                                <AriaCheckbox value='Groups'>
                                    {t("terms.group", 2)}
                                </AriaCheckbox>
                            </>
                        ) : undefined}
                    </Filters>
                }
                selectedFilters={filter}
            />
            <ModalFormContainer
                showModal={groupCreationIsActive}
                setShowModal={(val: boolean) => dispatch(setChannelCreation(val))}
            >
                <Suspense fallback={<LoadingSpinner color='mamba'/>}>
                    {groupCreationIsActive ? (
                        <NewChat
                            closeEditor={() => dispatch(setChannelCreation(false))}
                        />
                    ) : null}
                </Suspense>
            </ModalFormContainer>
            <Suspense fallback={<ChatListSkeleton/>}>
                <ChatList
                    chatsLoading={chatsLoading}
                    dataSet={dataSet}
                    displayChats={page === 'chats' ? chats : threads}
                    page={page}
                    searchTerm={searchTerm}
                />
            </Suspense>
        </div>
    );
};
