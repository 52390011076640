import React, { FunctionComponent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { DropdownIndicatorProps, StylesConfig } from 'react-select';
import styles from './styles.module.scss';
import ariaStyles from '../AriaComponents/styles.module.scss';
import AriaLabel from '../AriaComponents/AriaLabel';
import Icon from '../Icon';
import StyledDropdown from '../StyledComponents/StyledDropdown';
import AriaButton from '../AriaComponents/AriaButton';

type Props = {
    name: string;
    label: string;
    required?: boolean;
    readOnly?: boolean;
    options: {
        label: string;
        value: string;
    }[];
};

export const CustomDropdownIndicator: React.FC<DropdownIndicatorProps> = () => (
    <div className={styles.dropDownIcon}>
        <Icon
            name="arrowDown"
            width={24}
            height={24}
        />
    </div>
);

export const selectDefaultStyles: StylesConfig = {
    option: (optionStyles, { isDisabled, isFocused, isSelected }: any) => ({
        ...optionStyles,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isDisabled
            ? undefined
            // eslint-disable-next-line no-nested-ternary
            : isSelected
                ? 'var(--tertiary-color)'
                : isFocused
                    ? 'var(--secondary-background)'
                    : undefined,
        // eslint-disable-next-line no-nested-ternary
        color: isDisabled
            ? 'var(--secondary-font-color)'
            // eslint-disable-next-line no-nested-ternary
            : isSelected
                ? 'var(--font-color-white)'
                : 'var(--primary-font-color)',
        cursor: isDisabled ? 'not-allowed' : 'default',
    }),
    placeholder: () => ({
        color: 'var(--secondary-font-color)',
        gridArea: '1/1/2/3',
        boxSizing: 'border-box'
    }),
    indicatorSeparator: () => ({
        width: 4,
        height: '100%'
    })
};

const FormSelect: FunctionComponent<Props> = ({ name, options, label, required, readOnly }) => {
    const { control } = useFormContext();

    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div className={styles.form_select_input}>
            <AriaLabel label={label} />
            {required ? (
                <mark>*</mark>
            ) : null}
            <Controller
                name={name}
                control={control}
                render={({
                             field: { onChange, value },
                             fieldState: { invalid, isTouched, isDirty, error }
                         }) => (
                    <>
                        <div
                            data-invalid={invalid || null}
                            data-is-touched={isTouched || null}
                            data-is-dirty={isDirty || null}
                            data-error={!!error || null}
                        >
                            <StyledDropdown
                                options={options}
                                selected={value}
                                onSelect={(val) => {
                                    const selectedOption = options.find(option => option.value === val);
                                    if (selectedOption) {
                                        onChange(selectedOption.value);
                                    }
                                }}
                                disabled={readOnly}
                                direction="right"
                                placeHolder="Select an option"
                                iconOnly={false}
                                iconSize="small"
                                buttonOverride={(
                                    <AriaButton
                                        className={styles.selectDrop}
                                        onClick={() => setDropdownOpen(!dropdownOpen)}
                                        dataTags={{
                                            'data-error': error?.message ? 'true' : null
                                        }}
                                        data-thing="this"
                                    >
                                        {options.find(option => option.value === value)?.label || 'Select'}
                                        <Icon className={styles.icon} name="arrowDown" />
                                    </AriaButton>
                                )}
                                open={dropdownOpen}
                                setOpen={setDropdownOpen}
                            />
                            {error?.message ? (
                                <div className={ariaStyles.errorMessage}>
                                    {error.message}
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
            />
        </div>
    );
};

export default FormSelect;
